(function () {
  'use strict';

  class UserRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    login() {
      return this.$resource(`${this.env.api}/sessions/login`);
    }

    assignAccount() {
      return this.$resource(`${this.env.api}/users/assign-account`);
    }

    invitations() {
      return this.$resource(`${this.env.api}/users/invitations/:invitation_code`, {invitation_code: '@invitationCode'});
    }

    users() {
      return this.$resource(`${this.env.api}/sessions/:passwordRecovery`, {passwordRecovery: '@passwordRecovery'}, {update: {method: 'PUT'}});
    }

    teachers() {
      return this.$resource(`${this.env.api}/teachers/:id`, {id: '@teacher.id'}, {update: {method: 'PUT'}});
    }

    administrators() {
      return this.$resource(`${this.env.api}/administrators/:id`, {id: '@admin.id'}, {update: {method: 'PUT'}});
    }

    importUsers() {
      return this.$resource(`${this.env.api}/users/import`, null, {save: {method: 'POST', transformRequest: angular.identity, headers: {'Content-Type': undefined}}});
    }

    myStudents() {
      return this.$resource(`${this.env.api}/users/my-students`, null, {update: {method: 'PUT'}});
    }

    user() {
      return this.$resource(`${this.env.api}/users/:id`, {id: '@user.id'}, {update: {method: 'PUT'}});
    }

    students() {
      return this.$resource(`${this.env.api}/users/codes/:creation_code`, {creation_code: '@creation_code'}, {update: {method: 'PUT'}});
    }

    userReviews() {
      return this.$resource(`${this.env.api}/users/:id/reviews/:review_id`, {id: '@id', review_id: '@reviewId'}, {update: {method: 'PUT'}});
    }

    profile() {
      return this.$resource(`${this.env.api}/users/:id/profile`, {id: '@id'});
    }

    studentProgress() {
      return this.$resource(`${this.env.api}/users/:id/student-progress`, {id: '@id'}, {update: {method: 'PUT'}});
    }
  }

  /**
   * @ngdoc service
   * @name components.service:UserRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('UserRest', UserRest);
}());
